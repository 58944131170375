import type { ParsedUrlQuery } from 'querystring';
import type { Atom } from 'jotai';

import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { z } from 'zod';

import { store } from '@/providers/AppProvider'

export const routerQueryAtom = atom<Record<string, string>>({})

export function useJotaiRouterQuery(query: ParsedUrlQuery) {
  const router = useRouter()
  const setRouterQuery = useSetAtom(routerQueryAtom)

  if (query) {
    store.set(routerQueryAtom, query as Record<string, string>)
  }

  useEffect(() => {
    setRouterQuery(router.query as Record<string, string>)
  }, [router, setRouterQuery])
}

export function atomWithTypedRouterQuery<T extends z.Schema>(schema: T) {
  const theAtom: Atom<z.infer<typeof schema>> = atom((get) => {
    const query = get(routerQueryAtom)
    return schema.parse(query) as z.infer<typeof schema>
  })
  return theAtom
}
