import type { PropsWithChildren } from 'react';

import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider, unstable_createStore } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query'
import { trpcQuery, trpcQueryClient } from './trpc'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false
      // onError: () => {
      //   toaster.negative('Something went wrong. Please try again later.', {})
      // },
    }
  }
});

/**
 * It's important keep a store object globally as we mixed use of react-three-fiber, sharing values between
 * different react renderer.
 *
 * @link https://github.com/pmndrs/jotai/issues/683
 */
export const store = unstable_createStore([[queryClientAtom, queryClient]]);

export default function AppProvider({ children, ...props }: PropsWithChildren<{dehydratedState?: unknown}>) {
  return (
    <trpcQuery.Provider client={trpcQueryClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <JotaiProvider unstable_createStore={() => store}>
          <Hydrate state={props.dehydratedState}>
            {children}
          </Hydrate>
        </JotaiProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </trpcQuery.Provider>
  );
}
