import type { NextComponentType, NextPageContext } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement } from 'react'

import { GoogleAnalytics } from 'nextjs-google-analytics'
import { propOr } from 'ramda'
import GlobalStyles from '../styles/GlobalStyles'
import Router from 'next/router'
import NProgress from  'nprogress'
import { trpcNext } from '@/providers/trpc'

import { useJotaiRouterQuery } from '@/atoms/routerQueryAtom'

interface ComponentWithLayout {
  getLayout?: (page: ReactElement) => ReactElement
}

interface AppLayoutProps extends AppProps {
  Component: NextComponentType<NextPageContext, any, any> & ComponentWithLayout;
}

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps, router: serverRouter }: AppLayoutProps) {
  const getLayout = Component.getLayout || ((page: ReactElement, _dehydratedState?: unknown) => page)
  const dehydratedState = propOr(null, 'dehydratedState', pageProps)

  useJotaiRouterQuery(serverRouter.query)

  return (
    <>
      <GlobalStyles />
      <GoogleAnalytics trackPageViews />
      {getLayout(<Component {...pageProps} />, dehydratedState)}
    </>
  )
}

export default trpcNext.withTRPC(MyApp)
