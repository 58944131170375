import type { AppRouter } from '@phalaworld/api';

import { createTRPCReact } from "@trpc/react-query";
import { createTRPCNext } from '@trpc/next';
import { createTRPCProxyClient, httpBatchLink, httpLink } from '@trpc/client';
import SuperJSON from 'superjson';
import { Decimal } from 'decimal.js';
import { createTRPCJotai } from 'jotai-trpc'

const TRPC_ENDPOINT = process.env.NEXT_PUBLIC_TRPC_ENDPOINT as string

SuperJSON.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: v => v.toJSON(),
    deserialize: v => new Decimal(v),
  },
  'decimal.js'
)

export const trpcQuery = createTRPCReact<AppRouter>();

export const trpcQueryClient = trpcQuery.createClient({
  transformer: SuperJSON,
  links: [
    httpBatchLink({
      url: TRPC_ENDPOINT
    })
  ]
})

export const trpcJotai = createTRPCJotai<AppRouter>({
  transformer: SuperJSON,
  links: [
    httpLink({
      url: TRPC_ENDPOINT
    }),
  ],
})

export const trpcNext = createTRPCNext<AppRouter>({
  config({ ctx }) {
    if (typeof window !== 'undefined') {
      // during client requests
      return {
        transformer: SuperJSON, // optional - adds superjson serialization
        links: [
          httpBatchLink({ url: TRPC_ENDPOINT })
        ],
      };
    }
    return {
      transformer: SuperJSON, // optional - adds superjson serialization
      links: [
        httpBatchLink({
          // The server needs to know your app's full url
          // url: `${getBaseUrl()}/api/trpc`,
          url: TRPC_ENDPOINT,
          /**
           * Set custom request headers on every request from tRPC
           * @link https://trpc.io/docs/v10/header
           */
          headers() {
            if (ctx?.req) {
              // To use SSR properly, you need to forward the client's headers to the server
              // This is so you can pass through things like cookies when we're server-side rendering
              // If you're using Node 18, omit the "connection" header
              const {
                // eslint-disable-next-line
                connection: _connection,
                ...headers
              } = ctx.req.headers;
              return {
                ...headers,
                // Optional: inform server that it's an SSR request
                'x-ssr': '1',
              };
            }
            return {};
          },
        }),
      ],
    };
  },
  ssr: true,
})

export const trpcVanilla = createTRPCProxyClient<AppRouter>({
  transformer: SuperJSON,
  links: [
    httpBatchLink({
      url: TRPC_ENDPOINT
    })
  ]
})
